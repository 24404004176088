export const interviews = [
  {
    id: 1,
    level: 'Middle',
    groups: [
      {
        name: 'Introduction',
        questions: [
          'Чим займався на проекті? Які були конкретно в тебе обов’язки?'
        ],
      },
      {
        name: 'OOП',
        questions: [
          'Принципи ООП. Поясни мені абстракцію?',
          'Що таке overload? Для чого він потрібен?',
          'Як розширити interface двома парент інтерфейсами?'
        ],
      },
      {
        name: 'Generic',
        questions: [
          'Для чого потрібні Generic?',
          'Як раніше хендлили безпеку типів без Generic?'
        ],
      },
      {
        name: 'Reflection',
        questions: [
          'Як отримати доступ до метаінформації?'
        ],
      },
      {
        name: 'Collection',
        questions: [
          'Чому Map не успадковується від Collection?',
          'Які є види Map?',
          'Які плюси та мінуси HashMap?',
          'Структура HashMap?(Що там під капотом)',
          'Швидкість вставки в середину LinkedList?',
          'Швидкість вставки в середину ArrayList?'
        ],
      },
      {
        name: 'JDBC/JPA',
        questions: [
          'Яка є перевага JDBC над JPA ?',
          'Які є стейтменти в JDBC?',
          'Який стейтмент безпечніший?'
        ],
      },
      {
        name: 'Spring',
        questions: [
          'Перелічи елементи архітектури  Spring Core?',
          'Розкажи про Dependency Injection?',
          'Як є способи заінжексити залежності?',
          'Чим POJO відрізняється від Bean?',
          'Для чого потрібен ApplicationContext?',
          'Які є скопи бінів?',
          'Різниця між анотаціями @Controller та @RestController ?',
          'Для чого потрібен @RequestBody?',
          'Для чого потрібен @ResponseBody?'
        ],
      },
      {
        name: 'Spring Security',
        questions: [
          'Для чого потрібен JWT?',
          'Чи можна не експайрити токен рік? Які є недоліки в цьому',
          'Який механізм рефрешу токенів?'
        ],
      },
      {
        name: 'Spring Data',
        questions: [
          'Різниця між Criteria, JPQL, HQL ?'
        ],
      },
      {
        name: 'Різне',
        questions: [
          'Які є архитектури додатків?',
          'Коли використовувати моноліт а коли мікросервиси?',
          'Чи використовували AWS на проекті?',
          'Як закинути файл на EC2?',
          'Чи використовував Jenkins?',
          'Які команди лінуксу використовував?'
        ],
      }
    ]
  },
  {
    id: 2, // assuming a new ID for this set
    level: 'Middle',
    groups: [
      {
        name: 'Introduction',
        questions: [
          'Опиши ієрархію виключень (англійською)',
          'Які checked і unchecked виключення ти знаєш, назви по два приклади?'
        ],
      },
      {
        name: 'Багатопоточність',
        questions: [
          'Чи використовував багатопоточність на проєктах, наведи приклади?',
          'Класичний приклад з каунтером якій змінюється двома потоками. Як уникнути неправильної роботи інкременту?',
          'Чи допоможе нам volotile в цьому випадку?',
          'Що таке атомарність в контексті багатопоточності?',
          'Як працюють Atomic класи?',
          'Розкажи що є в пакеті concurrency?'
        ],
      },
      {
        name: 'Різне(WEB, Clouds, NoSQL)',
        questions: [
          'Чи працював з GraphQL?',
          'Чим відрізняються REST від GraphQL?',
          'Чи працював з Terraform, яку функцію виконує?',
          'З якими клаудами працював, які задачі виконував? (AWS, Azure, GCP)',
          'З якими базами працював, як SQL так і NoSQL?',
          'Що таке CAP теорема загалом і в контексті NoSQL?'
        ],
      },
      {
        name: 'Hibernate',
        questions: [
          'Які є fetch type?',
          'Як хібернейт розуміє що йому взяти коли ми викликаємо getCollections() з fetch type Eager?',
          'Чи може бути fetch type Lazy у OneToOne зв’язку?',
          'Чи допомогає нам fetch type Eager з 1+n проблемою? (В яких випадках не допомагає?)',
          'Що таке JPA, і Hibernate?',
          'Чим відрізняється HQL і JPQL?'
        ],
      },
      {
        name: 'Із нашого зідзвону',
        questions: [
          'Що таке volatile і як працює?',
          'Що означає fetch в HQL?',
          'Які є concurrency collections і як вони працюють?',
          'compareAndSet як працює?'
        ],
      },
      {
        name: 'Багато питань на подумать',
        questions: [
          'Як зробити кеш без готових рішень тільки використовуючи java? (WeakMap)',
          'Що буде ключем мапи кешу?',
          'Які будуть проблеми з таким кешом (просто мапа)?',
          'Як би ти організував чистку кеша? Ми ось можемо зберігати лише 1000 елементів.',
          'Ось в нас є кінотеатр, як організувати бронювання квитків просто за допомогою java?',
          'Чи потрібно використовувати synchronized блоки якщо ми використовуємо concurrent коллекції?',
          'Як називається ліст із concurrent пакета?',
          'Як би ти організував бронювання квитків з мікросервісами?',
          'Як відбувається оптімістік lock? Саме послідовність і як це допоможе з нашою проблемою?'
        ],
      },
      {
        name: 'Spring',
        questions: [
          'В мене є спрінг, є різні реалізації бінів різних бібліотек і я хочу підіймати їх залежно від енвайроменту?(Profile, ConditionalBeans)',
          'Класичне питання про транзакції, два метода помічені @Transactional із одного викликаємо інший чи буде транзакція у обох методів? Чому ні?',
          'А якщо ми таки хочемо, то як нам це зробити?',
          'Як можна заінджектити бін сам в себе?',
          'В нас є різні способи авторізації, різні реалізації одного інтерфейса і нам потрібно заінжектити всі одразу?'
        ],
      },
      {
        name: 'Бази данних',
        questions: [
          'Типи join?',
          'Чим відрізняється left join, від left outer join?',
          'Чим відрізняється union і union all?',
          'Які є проблеми під час міграції, з якими зіштовхувався? (flyway)',
          'Які можуть бути проблеми з додаванням колонки в таблицю на дуже велику кількість елементів?(migration)',
          'Які ти знаєш локи в бд?',
          'Як використовував SQL на проєктах?',
          'Які проблеми з використанням ENUM в БД?'
        ],
      }
    ]
  },
];